import React from "react";
import classes from "./FaqPage.module.css";
import cn from "clsx";
import Table from "src/components/Table";
import Typography from "src/components/ui-kit/Typography";
import { T, useLocale } from "react-targem";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import years from "content/years.json";

const FaqPageHowWasCollected = () => {
  const { t } = useLocale();

  const tables = [
    {
      columns: [
        { title: t("статья"), key: "clause" },
        { title: t("всего осуждено"), key: "total" },
      ],
      rows: [
        {
          key: "134 ч. 1 (в старой редакции до ФЗ от 29.02.2012 № 14-ФЗ)",
          values: [
            {
              key: "clause",
              value: t(
                "134 ч. 1 (в старой редакции до ФЗ от 29.02.2012 № 14-ФЗ)"
              ),
            },
            { key: "total", value: "2" },
          ],
        },
        {
          key: "134 ч. 4 (в старой редакции до ФЗ от 29.02.2012 № 14-ФЗ)",
          values: [
            {
              key: "clause",
              value: t(
                "134 ч. 4 (в старой редакции до ФЗ от 29.02.2012 № 14-ФЗ)"
              ),
            },
            { key: "total", value: "0" },
          ],
        },
        {
          key: "134 ч. 1 (с редакцией ФЗ от 29.02.2012 № 14-ФЗ)",
          values: [
            {
              key: "clause",
              value: t("134 ч. 1 (с редакцией ФЗ от 29.02.2012 № 14-ФЗ)"),
            },
            { key: "total", value: "2287" },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Typography>
        <T message="Мы берем уголовную статистику с" />{" "}
        <OutboundLink
          href="http://www.cdep.ru/index.php?id=5"
          target="_blank"
          rel="noreferrer"
        >
          <T message="сайта" />
        </OutboundLink>{" "}
        <T message="Судебного департамента при Верховном суде РФ. Это информация о судимости и о решениях федеральных арбитражных судов, судов общей юрисдикции и мировых судей. Для обработки мы выбрали два типа данных: статистику осужденных по основному составу преступления (без учета сложения наказаний) и по дополнительному. Демографические формы, которые содержат информацию о социальном статусе обвиняемых, их поле и возрасте, мы не обрабатывали." />
      </Typography>
      <Typography isUpperCased>
        <b>
          <T message="Вот как мы обработали эту информацию:" />
        </b>
      </Typography>
      <Typography>
        <ol>
          <li>
            <T
              message="Свели в единую таблицу несколько массивов данных: статистику осужденных по основным составам за 2009-2010 и 2011-2018 годы и статистику осужденных по дополнительным составам за 2009-{{ year }} годы."
              scope={{ year: years[0] }}
            />
          </li>
          <li>
            <T message="Из полученных таблиц мы удалили пустые и резервные строки, а также те, в которых была указана только степень тяжести преступления. Показатели этих строк либо равно нулю, либо уже учтены." />
          </li>
          <li>
            <T message="Привели данные в машиночитаемый вид и представили в виде таблицы в «длинной форме» (такая форма считается наиболее удобной для машинной обработки и соответствует принципам" />
            <OutboundLink
              href="https://www.jstatsoft.org/article/view/v059i10/v59i10.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <T message="«чистых данных»" />
            </OutboundLink>
            ), <T message="а затем обработали с помощью Python." />
          </li>
          <li>
            <T message="Провели базовую аналитику основных показателей и проиллюстрировали их наглядной инфографикой, которая доступна по данным каждой статьи." />
          </li>
        </ol>
      </Typography>
      <Typography isUpperCased>
        <b>
          <T message="С какими проблемами мы столкнулись?" />
        </b>
      </Typography>
      <Typography>
        <T message="Из-за особенностей уголовного права РФ в статистике оказываются данные, которые затрудняют сбор и анализ: некоторые статьи встречаются несколько раз, вид и содержание таблиц не унифицированы, а новые законы требуют более внимательной обработки. " />
      </Typography>
      <ul className={cn(classes.ulWithAdditionalMargin)}>
        <li>
          <Typography>
            <b>
              <T message="Изменения в статистических формах" />
            </b>

            <br />
            <T message="Статистические формы Судебного департамента меняются со временем: в них увеличивается количество учитываемых показателей и их детализация, а также количество самих статей УК. Например, до 2018 года наказание в виде штрафа не было детализировано по суммам штрафа. Некоторые показатели, наоборот, исчезают. Так, колонка по количеству осужденных, у которых имеется коррупционный мотив, появилась в статистике в 2010 году и исчезла в 2012." />
            <br />
            <br />
            <T message="Отчасти эта проблема объясняется изменениями в самом Уголовном кодексе: количество статей «Особенной части» постоянно растет, что ведет к увеличению показателей." />
          </Typography>
        </li>
        <li>
          <Typography>
            <b>
              <T message="Утратившие силу статьи" />
            </b>
            <br />
            <T message="Порой в судебную статистику попадают новые дела по уже не действительным статьям. Так, в 2011 году часть 1" />{" "}
            <OutboundLink
              href="http://www.consultant.ru/cons/cgi/online.cgi?n=121948&from=121948-2323-diff&req=doc&base=LAW&rnd=9FF5E32582FBA35F001CB5DE0BE9D263#08686279044813056"
              target="_blank"
              rel="noreferrer"
            >
              <T message="статьи 188" />
            </OutboundLink>{" "}
            —{" "}
            <T message="контрабанда — была декриминализована и перешла в разряд" />{" "}
            <OutboundLink
              href="http://www.consultant.ru/document/cons_doc_LAW_34661/894132ba3e2f42ab935398b530d55bc1f569337f/"
              target="_blank"
              rel="noreferrer"
            >
              <T message="административных правонарушений" />
            </OutboundLink>
            ,{" "}
            <T message="а преступления из части 2 — контрабанда запрещенных веществ, огнестрельного оружия, боеприпасов и прочего — были перенесены в статьи" />{" "}
            <OutboundLink
              href="http://www.consultant.ru/document/cons_doc_LAW_10699/9164eeb910585b91c4cabcf22804cc33e804a316/"
              target="_blank"
              rel="noreferrer"
            >
              <T message="226.1" />
            </OutboundLink>{" "}
            <T message="и" />{" "}
            <OutboundLink
              href="http://www.consultant.ru/document/cons_doc_LAW_10699/9164eeb910585b91c4cabcf22804cc33e804a316/"
              target="_blank"
              rel="noreferrer"
            >
              <T message="229.1" />
            </OutboundLink>
            ,{" "}
            <T message="при этом наказание за них не изменилось. В настоящей редакции Уголовного кодекса статья 188 значится как утратившая силу. Тем не менее, в 2017 году по ней осудили двух человек." />
            <br />
            <br />
            <T message="Это возможно из-за" />{" "}
            <mark>
              <T message="принципа действия закона во времени" />
            </mark>{" "}
            <T message="(ст. 9 УК): даже через несколько лет после преступления человека, совершившего его, осудят в соответствии с той редакцией, которая действовала на момент совершения преступления. В случае статьи 188 ч. 2, Верховный суд" />{" "}
            <OutboundLink
              href="http://docs.pravo.ru/document/view/27031636"
              target="_blank"
              rel="noreferrer"
            >
              <T message="предписывает" />
            </OutboundLink>{" "}
            <T message="использовать прежнюю статью 188 для тех преступлений, которые были совершены в период действия 188 статьи и попадают под ее вторую часть." />
          </Typography>
        </li>
        <li>
          <Typography>
            <b>
              <T message="Появление новых статей в УК РФ " />
            </b>
            <br />
            <T message="Новые статьи смещают практику правоприменения. Например, до 2016 года по обвинению в терроризме привлекали по статьям 205, 205.1-5, 206, 207. После принятия «закона Яровой» их стало больше: появились также статьи 205.6 и 361. Анализируя данные только по одной статье, мы можем прийти к выводу, что с годами с России стали реже судить за терроризм, однако в реальности дело в расширении законодательства и увеличении числа статей." />
          </Typography>
        </li>
        <li>
          {" "}
          <Typography>
            <b>
              <T message="Изменение содержания статей и задваивание в статистике" />
            </b>
            <br />
            <T message="Некоторые статьи претерпевают существенные изменения. В таком случае утрачивает силу прежняя редакция статьи. Но так как, по тому же принципу" />{" "}
            <mark>
              <T message="действия закона во времени" />
            </mark>
            ,{" "}
            <T message="по прежней редакции могут продолжать осуждать людей, в таблицах иногда соседствуют разные версии одной и той же статьи. Например, в данных по" />{" "}
            <OutboundLink
              href="https://demo.consultant.ru/cgi/online.cgi?req=doc&n=123339&base=RZR&from=123339-1483-diff&rnd=55358525FE4A98BD84F90CEA67036353#09408714866443109"
              target="_blank"
              rel="noreferrer"
            >
              <T message="статье 134 УК" />
            </OutboundLink>{" "}
            <T message="в 2017 году все части статьи встречаются дважды." />
            <br />
            <br />
            <Table
              title={t("Список статей")}
              isEqualWidth
              hideEmbed
              isNotPaddedLeft
              isNotMarginTop
              tables={tables}
            />
            <br />
            <br />
            <T message="В случаях, когда статья, еe часть или редакция утрачивают силу, в статистических формах № 10.3 и № 10.3.1 номер статьи сопровождается комментарием. Такие примечания содержат ценную информацию об изменениях статей, однако затрудняют автоматическое сопоставление статей в разных года. К тому же, написание примечаний может различаться в статистических формах за разные года: «редакция» или «ред.»." />
          </Typography>{" "}
        </li>
        <li>
          {" "}
          <Typography>
            <b>
              <T message="Отсутствие детализированных данных по некоторым статьям" />
            </b>
            <br />
            <T message="Мы исправили несколько несоответствий в списке статей и частей УК РФ в разных файлах. Например, в таблицах за 2012 год часть 1 статьи 133 записана в форме № 10.3 с примечанием, в котором зафиксировано появление у статьи частей, а в форме № 10.3.1 — без этого примечания." />
            <br />
            <br />
            <T message="Мы обнаружили и более существенные расхождения. Так, в форме № 10.3 за 2016 год приведены лишь агрегированные данные по статьям 204.1 и 204.2 без указания статистики по отдельным частям этих статей. Чтобы восстановить детализацию, отсутствующую в форме № 10.3, мы использовали информацию из форм № 10.3.1 и № 10-а, где эти же статьи даны с детализацией по частям." />
          </Typography>
        </li>
        <li>
          <Typography>
            <b>
              <T message="Расхождение данных в одинаковых по смыслу столбцах разных отчетных форм" />
            </b>
            <br />
            <T message="В таких случаях были использованы данные  формы 10.3." />
          </Typography>
        </li>
      </ul>
    </>
  );
};

export default FaqPageHowWasCollected;
