import React from "react";
import classes from "./FaqPage.module.css";
import cn from "clsx";
import Typography, {
  TypographyContainer,
} from "src/components/ui-kit/Typography";
import { T, useLocale } from "react-targem";
import Table from "src/components/Table";
import metrics from "content/metriсs.json";
import Tooltip from "src/components/ui-kit/Tooltip";

const FaqPageUploadFieldsNParameterTree: React.FC = () => {
  const { t } = useLocale();

  const tables = [
    {
      columns: [
        { title: "", key: "1" },
        { title: "", key: "2" },
      ],
      rows: metrics.map((m) => ({
        key: m.key,
        values: [
          {
            key: `${m.key}-key`,
            value: t(m.key),
          },
          {
            key: `${m.key}-title`,
            value: t(m.title),
          },
        ],
      })),
    },
  ];

  return (
    <>
      <Typography>
        <T message="Для простой навигации по базе данных мы создали «дерево» параметров: оно демонстрирует, какие индикаторы мы анализировали, как они связаны между собой и как с ними можно работать. Все показатели поделены на три раздела: основной состав, дополнительные характеристики по основному составу и дополнительный состав." />
      </Typography>
      <Typography>
        <T message="В некоторых случаях невозможно с уверенностью утверждать, как в статистике интерпретированы те или иные индикаторы, — Судебный департамент не предоставляет дополнительную информацию о сборе данных. Показатели, которые можно отнести к двум категориям, встречаются в «дереве» несколько раз и снабжены комментариями. Одинаковые показатели, обозначенные в таблицах по-разному, объединены в один пункт." />
      </Typography>
      <Typography isUpperCased>
        <b>
          <T message="Основной состав" />
        </b>
      </Typography>
      <TypographyContainer>
        <ol type="I" className={cn(classes.ulWithAdditionalMargin)}>
          <li>
            <b>
              <T message="Всего осуждено" />
            </b>
            {" (totalConvicted). "}
            <T message="Показатель описывает случаи, когда суд признал человека виновным и назначил наказание:" />
            <ol>
              <li>
                <b>
                  <T message="Пожизненное лишение свободы" />
                </b>{" "}
                (primaryLifeSentence)
              </li>
              <li>
                <b>
                  <T message="Лишение свободы" />
                </b>
                {" (primaryImprisonment). "}
                <T message=" Категории нет в таблице, дана детализация по срокам наказания:" />
                <ol type="a">
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="до 1 года включительно" />
                    </b>
                    {" (primaryImprisonment1);"}
                  </li>
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="от 1 до 3 лет включительно" />
                    </b>
                    {" (primaryImprisonment1_3)"}
                    <T message="— в некоторых таблицах Судебного департамента детализация по этим данным представлена единым блоком, в других дана раздельно по следующим категориям:" />
                    <ul>
                      <li>
                        <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                        <b>
                          <T message="свыше 1 до 2 лет включительно" />
                        </b>
                        {" (primaryImprisonment1_2)"}
                      </li>
                      <li>
                        <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                        <b>
                          <T message="свыше 2 до 3 лет включительно" />
                        </b>{" "}
                        (primaryImprisonment2_3)
                      </li>
                    </ul>
                  </li>
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="свыше 3 до 5 лет включительно" />
                    </b>{" "}
                    (primaryImprisonment3_5)
                  </li>
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="свыше 5 до 8 лет включительно" />
                    </b>{" "}
                    (primaryImprisonment5_8)
                  </li>
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="свыше 8 до 10 лет включительно" />
                    </b>{" "}
                    (primaryImprisonment8_10)
                  </li>
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="свыше 10 до 15 лет включительно" />
                    </b>{" "}
                    (primaryImprisonment10_15)
                  </li>
                  <li>
                    <T message="Лишение свободы: количество осужденных по срокам" />{" "}
                    <b>
                      <T message="свыше 15 до 20 лет включительно" />
                    </b>{" "}
                    (primaryImprisonment15_20)
                  </li>
                </ol>
              </li>
              <li>
                <b>
                  <T message="Арест" />
                </b>{" "}
                (primaryArrest)
              </li>
              <li>
                <b>
                  <T message="Ограничение свободы" />
                </b>
                {" (primaryRestrain) "}
                <T message="и" />{" "}
                <b>
                  <T message="ограничение свободы/ограничение по военной службе, содержание в дисциплинарной воинской части" />
                </b>
                {" (primaryRestrain2009). "}
                <T message="Это одинаковые показатели, но в таблицах они обозначены по-разному. Показатель primaryRestrain2009 относится только к данным 2009 года." />
              </li>
              <li>
                <b>
                  <T message="Ограничения по воинской службе" />
                </b>
                {" (primaryRestrictionsInMilitaryService). "}
                <T message="Этот показатель частично пересекается с пунктом I.4." />
                <Tooltip tip={t("раздел «Основной состав», пункт I.4.")} />
                <T message="Вероятно, в некоторых таблицах эта мера наказания была объединена с другими, затем статистические формы поменяли" />
              </li>
              <li>
                <b>
                  <T message="Исправительные работы" />
                </b>{" "}
                (primaryCorrectionalLabour)
              </li>
              <li>
                <b>
                  <T message="Обязательные работы" />
                </b>{" "}
                (primaryCommunityService)
              </li>
              <li>
                <b>
                  <T message="Принудительные работы" />
                </b>{" "}
                (primaryForcedLabour)
              </li>
              <li>
                <b>
                  <T message="Штраф (основное наказание)" />
                </b>
                {" (primaryFine). "}
                <T message="Дана детализация по сумме штрафа:" />
                <ol type="a">
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="до 5 тысяч рублей" />
                    </b>{" "}
                    (primaryFine5);
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 5 тыс. до 25 тыс. рублей" />
                    </b>{" "}
                    (primaryFine5_25);
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 25 тыс. до 500 тыс. рублей" />
                    </b>{" "}
                    (primaryFine25_500);
                    <ul>
                      <li>
                        <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 25 тыс. до 100 тыс. рублей" />
                        </b>{" "}
                        (primaryFine25_100);
                      </li>
                      <li>
                        <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 100 тыс. до 300 тыс. рублей" />
                        </b>{" "}
                        (primaryFine100_300);
                      </li>
                      <li>
                        <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 300 тыс. до 500 тыс. рублей" />{" "}
                        </b>
                        (primaryFine300_500);
                      </li>
                    </ul>
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 500 тыс. до 1 млн рублей" />{" "}
                    </b>
                    (primaryFine500_1M);
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 1 млн рублей" />{" "}
                    </b>
                    (primaryFine1M);
                  </li>
                </ol>
              </li>
              <li>
                <b>
                  <T message="Лишение права занимать определенные должности или заниматься определенной деятельностью" />
                </b>{" "}
                (primaryDisqualification);
              </li>
              <li>
                <b>
                  <T message="Содержание в дисциплинарной воинской части" />
                </b>{" "}
                (primaryMilitaryDisciplinaryUnit);
              </li>
              <li>
                <b>
                  <T message="Условное осуждение к иным мерам" />
                </b>{" "}
                <T message="Эта категория не квалифицируется как мера наказания (принято считать это наказанием, совмещенным с освобождением от него), однако в данных Судебного департамента она дана как еще один вид наказания." />
              </li>
              <li>
                <b>
                  <T message="Условное осуждение к лишению свободы" />
                </b>
                {" (primarySuspended). "}
                <T message="Эта категория не квалифицируется как мера наказания (принято считать это наказанием, совмещенным с освобождением от него), однако в данных Судебного департамента она дана как еще один вид наказания." />
              </li>
            </ol>
          </li>
          <li>
            <b>
              <T message="Оправдано" />
            </b>
            {" (acquittal). "}
            <T message="Показатель описывает случаи, когда по решению суда человек признан невиновным." />
          </li>
          <li>
            <b>
              <T message="Прекращено" />
            </b>
            {" (dismissal). "}
            <T message="Показатель описывает случаи, когда судебное производство было прекращено. Категории нет в таблице, дана детализация по основаниям прекращения:" />
            <ol>
              <li>
                <b>
                  <T message="Реабилитирующие основания." />
                </b>{" "}
                <T message="Категории нет в таблице, дана детализация по видам:" />
                <ol type="a">
                  <li>
                    <b>
                      <T message="Прекращено за отсутствием события, состава, непричастностью к преступлению" />
                    </b>
                    {" (dismissalAbsenceOfEvent)."}
                  </li>
                  <li>
                    <b>
                      <T message="Обстоятельства, исключающие преступность деяния." />
                    </b>{" "}
                    <T message="Мы не знаем, интерпретирует ли Судебный департамент эти данные как детализацию прекращения дела по реабилитирующим обстоятельствам или как дополнительную характеристику по основному составу, поэтому включили эту категорию в оба раздела." />
                    <Tooltip
                      tip={t(
                        "раздел «Основной состав», пункт III.1.b. и раздел «Дополнительные характеристики по основному составу», пункт 7."
                      )}
                    />
                    <T message="Категории нет в таблице, дана детализация по видам:" />
                    <ul>
                      <li>
                        <T message="Обстоятельства, исключающие преступность деяния:" />{" "}
                        <b>
                          <T message="необходимая оборона" />
                        </b>
                        {" (noCrimeSelf-defence)"}
                      </li>
                      <li>
                        <T message="Обстоятельства, исключающие преступность деяния:" />{" "}
                        <b>
                          <T message="крайняя необходимость" />
                        </b>{" "}
                        (noCrimeNecessity)
                      </li>
                      <li>
                        <b>
                          <T message="Обстоятельства, исключающие преступность, предусмотренные статьями 38, 40-42 УК РФ" />
                        </b>{" "}
                        (noCrimeOther)
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <b>
                  <T message="Нереабилитирующие основания." />
                </b>{" "}
                <T message="Категории нет в таблице, дана детализация по видам:" />
                <ol type="a">
                  <li>
                    <b>
                      <T message="Прекращено по амнистии" />
                    </b>{" "}
                    (dismissalAmnesty)
                  </li>
                  <li>
                    <b>
                      <T message="Прекращено за примирением с потерпевшим" />
                    </b>{" "}
                    (dismissalReconciliation)
                  </li>
                  <li>
                    <b>
                      <T message="Прекращено в связи с деятельным раскаянием" />
                    </b>
                    {" (dismissalRepentance) "}
                    <T message="и" />{" "}
                    <b>
                      <T message="прекращено по другим основаниям: на основании примечаний к статьям УК РФ (в связи с деятельным раскаянием ч. 2 ст. 28 УПК РФ)" />
                    </b>
                    {" (dismissalRepentance2). "}
                    <T message="Мы не знаем, интерпретирует ли Судебный департамент эти данные как один или разные показатели. Это может быть детализация или наслоение данных" />
                  </li>
                  <li>
                    <b>
                      <T message="Прекращено с назначением судебного штрафа" />
                    </b>
                    {" (dismissalCourtFinе):"}
                    <ul>
                      <li>
                        <T message="Судебный штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="до 5 тыс. рублей" />
                        </b>
                        {" (dismissalCourtFine5)"}
                      </li>
                      <li>
                        <T message="Судебный штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 5 тыс. до 25 тыс. рублей" />
                        </b>
                        {" (dismissalCourtFine5_25)"}
                      </li>
                      <li>
                        <T message="Судебный штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 25 тыс. до 100 тыс. рублей" />
                        </b>
                        {" (dismissalCourtFine25_100)"}
                      </li>
                      <li>
                        <T message="Судебный штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 100 тыс. рублей" />
                        </b>
                        {" (dismissalCourtFine100)"}
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <b>
                  <T message="Прекращено по другим основаниям" />
                </b>
                {" (dismissalOther). "}
                <T message="Мы не знаем, какие именно основания входят в эту категорию. Здесь могут быть некоторые из реабилитирующих и нереабилитирующих оснований." />
              </li>
            </ol>
          </li>
          <li>
            <b>
              <T message="Принудительные меры к невменяемым" />
            </b>
            {" (coerciveMeasures). "}
            <T message="Показатель описывает случаи, когда к человеку были применены принудительные меры медицинского характера." />
          </li>
          <li>
            <b>
              <T message="Освобождение от наказания" />
            </b>
            {" (exemption). "}
            <T message="Мы не знаем, является ли эта категория в статистике Судебного департамента одним из исходов дела, наряду с обвинением и оправданием, или дополнительной характеристикой тех случаев, когда человека осудили" />
            {" ("}
            <T message="всего осуждено" />
            {" (totalConvicted)), "}
            <T message="поэтому мы включили её в оба раздела" />
            {"."}
            <Tooltip
              tip={t(
                "Раздел «Основной состав», пункт V и раздел «Дополнительные характеристики по основному составу», пункт V."
              )}
            />
            <T message="Категории нет в таблице, дана детализация по видам:" />
            <ol>
              <li>
                <T message="Освобождение от наказания" />{" "}
                <b>
                  <T message="по амнистии" />
                </b>
                {" (exemptionAmnesty)"}
              </li>
              <li>
                <T message="Освобождение от наказания" />{" "}
                <b>
                  <T message="по другим основаниям: лишение свободы" />
                </b>
                {" (exemptionFromImprisonment)"}
              </li>
              <li>
                <T message="Освобождение от наказания" />{" "}
                <b>
                  <T message="по другим основаниям: от иных мер или наказание не назначалось" />
                </b>
                {" (examptionOther)"}
              </li>
            </ol>
          </li>
        </ol>
      </TypographyContainer>
      <Typography isUpperCased>
        <b>
          <T message="Дополнительные характеристики по основному составу" />
        </b>
      </Typography>
      <TypographyContainer>
        <ol type="I" className={cn(classes.ulWithAdditionalMargin)}>
          <li>
            <b>
              <T message="Коррупционный мотив" />
            </b>
            {" (corruption). "}
            <T message="Дополнительная характеристика мотивов для тех, кого признали виновными" />
            {" ("}
            <T message="«Всего осуждено»" />
            {"  (totalConvicted)), "}{" "}
            <T message="данные есть за 2010-2012 годы включительно" />
            {"."}
          </li>
          <li>
            <b>
              <T message="Наказание назначено ниже низшего предела лишение свободы" />
            </b>
            {" (primaryImprisonmentUnderLowerLimit). "}
            <T message="Дополнительная характеристика для тех, кому назначили лишение свободы" />
            {" ((primaryImprisonment) "}
            <T message="— по всем детализациям" />
            {")."}
          </li>
          <li>
            <b>
              <T message="Преступление не является оконченным" />
            </b>{" "}
            <T message="(приготовление, покушение)" />
            {" (unfinishedOffence). "}
            <T message="Дополнительная характеристика по категории «Всего осуждено»" />
            {" (totalConvicted)."}
          </li>
          <li>
            <b>
              <T message="Общая сумма штрафов" />
            </b>
            {" (primaryFineSum). "}
            <T message="Дополнительная характеристика совокупности наказаний в виде штрафа в качестве основного наказания." />
          </li>
          <li>
            <b>
              <T message="Судебный штраф: общая сумма" />
            </b>
            {" (dismissalCourtFineSum). "}
            <T message="Дополнительная характеристика одного из видов прекращения судебного производства. Этот штраф не следует складывать со штрафом как видом наказания, основным или дополнительным." />
          </li>
          <li>
            <b>
              <T message="Освобождение от наказания" />
            </b>
            {" (exemption). "}
            <T message="Мы не знаем, является ли эта категория в статистике Судебного департамента одним из исходов дела, наряду с обвинением и оправданием, или дополнительной характеристикой тех случаев, когда человека осудили" />
            {" ("}
            <T message="всего осуждено" />
            {" (totalConvicted)), "}
            <T message="поэтому мы включили её в оба раздела" />
            {"."}
            <Tooltip
              tip={t(
                "Раздел «Основной состав», пункт V и раздел «Дополнительные характеристики по основному составу», пункт V."
              )}
            />
            <T message="Категории нет в таблице, дана детализация по видам:" />
            <ol>
              <li>
                <T message="Освобождение от наказания" />{" "}
                <b>
                  <T message="по амнистии" />
                </b>{" "}
                (exemptionAmnesty)
              </li>
              <li>
                <T message="Освобождение от наказания" />{" "}
                <b>
                  <T message="по другим основаниям: лишение свободы" />
                </b>{" "}
                (exemptionFromImprisonment)
              </li>
              <li>
                <T message="Освобождение от наказания" />{" "}
                <b>
                  <T message="по другим основаниям: от иных мер или наказание не назначалось" />
                </b>{" "}
                (exemptionOther)
              </li>
            </ol>
          </li>
          <li>
            <b>
              <T message="Дополнительные наказания." />
            </b>{" "}
            <T message="Дополнительная характеристика категории «Всего осуждено»" />
            {" (totalConvicted), "}
            <T message="показывает случаи, когда к основному наказанию суд назначает дополнительное за тот же состав преступления" />
            {"."}
            <T message="Категории нет в таблице, дана детализация по видам:" />
            <ol>
              <li>
                <T message="Дополнительное наказание:" />{" "}
                <b>
                  <T message="лишение права занимать определенные должности или заниматься определенной деятельностью" />
                </b>{" "}
                (addDisqualification)
              </li>
              <li>
                <T message="Дополнительное наказание:" />{" "}
                <b>
                  <T message="штраф" />
                </b>{" "}
                (addFine)
                <ol type="a">
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="до 5 тыс. рублей" />
                    </b>{" "}
                    (addFine5)
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 5 тыс. до 25 тыс. рублей" />
                    </b>{" "}
                    (addFine5_25)
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 25 тыс. до 500 тыс. рублей" />
                    </b>{" "}
                    (addFine25_500)
                    <ul>
                      <li>
                        <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 25 тыс. до 100 тыс. рублей" />
                        </b>{" "}
                        (addFine25_100)
                      </li>
                      <li>
                        <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 100 тыс. до 300 тыс. рублей" />
                        </b>{" "}
                        (addFine100_300)
                      </li>
                      <li>
                        <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                        <b>
                          <T message="свыше 300 тыс. до 500 тыс. рублей" />
                        </b>{" "}
                        (addFine300_500)
                      </li>
                    </ul>
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 500 тыс. до 1 млн рублей" />
                    </b>{" "}
                    (addFine500_1M)
                  </li>
                  <li>
                    <T message="Штраф: количество осужденных по размеру штрафа" />{" "}
                    <b>
                      <T message="свыше 1 млн рублей" />
                    </b>{" "}
                    (addFine1M)
                  </li>
                  <li>
                    <b>
                      <T message="Штраф (дополнительное наказание): общая сумма" />
                    </b>
                    {" (addFineSum). "}
                    <T message="Дополнительная характеристика показателя по штрафам в качестве дополнительного наказания. Может складываться с общей суммой штрафа как основного наказания." />
                  </li>
                </ol>
              </li>
              <li>
                <T message="Дополнительное наказание:" />{" "}
                <b>
                  <T message="лишение специального, воинского или почетного звания, классного чина и государственных наград" />
                </b>{" "}
                (addTitlesWithdraw)
              </li>
              <li>
                <T message="Дополнительное наказание:" />{" "}
                <b>
                  <T message="ограничение свободы" />
                </b>{" "}
                (addRestrain)
              </li>
            </ol>
          </li>
          <li>
            <b>
              <T message="Обстоятельства, исключающие преступность деяния." />
            </b>{" "}
            <T message="Мы не знаем, интерпретирует ли Судебный департамент эти данные как детализацию прекращения дел по реабилитирующим обстоятельствам или как дополнительную характеристику по основному составу, поэтому включили эту категорию в оба раздела." />
            <Tooltip
              tip={t(
                "раздел «Основной состав», пункт III.1.a. и раздел «Дополнительные характеристики по основному составу», пункт VII."
              )}
            />
            <T message="Категории нет в таблице, дана детализация по видам:" />
            <ol type="a">
              <li>
                <T message="Обстоятельства, исключающие преступность деяния:" />{" "}
                <b>
                  <T message="необходимая оборона" />
                </b>{" "}
                (noCrimeSelf-defence)
              </li>
              <li>
                <T message="Обстоятельства, исключающие преступность деяния:" />{" "}
                <b>
                  <T message="крайняя необходимость" />
                </b>{" "}
                (noCrimeNecessity)
              </li>
              <li>
                <b>
                  <T message="Обстоятельства, исключающие преступность, предусмотренные статьями 38, 40 - 42 УК РФ" />
                </b>{" "}
                (noCrimeOther)
              </li>
            </ol>
          </li>
        </ol>
      </TypographyContainer>
      <Typography isUpperCased>
        <b>
          <T message="Дополнительные характеристики по основному составу" />
        </b>
      </Typography>
      <TypographyContainer>
        <ol type="I" className={cn(classes.ulWithAdditionalMargin)}>
          <li>
            <b>
              <T message="Осуждено: по числу лиц" />
            </b>
            {" (addTotalPersons) "}
            <T message="и" />{" "}
            <b>
              <T message="по количеству составов преступлений" />
            </b>{" "}
            (addTotalOffences)
          </li>
          <li>
            <b>
              <T message="Оправдано: по числу лиц" />
            </b>
            {" (addAcquittalPersons) "}
            <T message="и" />{" "}
            <b>
              <T message="по количеству составов преступлений" />
            </b>
            {" (addAcquittalOffences)"}
          </li>
          <li>
            <b>
              <T message="Прекращено." />
            </b>{" "}
            <T message="Мы не знаем, какие именно основания включены в эту категорию. Это могут быть некоторые из реабилитирующих и нереабилитирующих оснований. Категории нет в таблице, дана детализация по основаниям прекращения:" />
            <ol>
              <li>
                <b>
                  <T message="Прекращено: по числу лиц" />
                </b>
                {" (addAcquittalOffences) "}
                <T message="и" />{" "}
                <b>
                  <T message="по количеству составов преступлений" />
                </b>
                {" (addDismissalOffences)"}
              </li>
              <li>
                <b>
                  <T message="Прекращено по другим основаниям: по числу лиц" />
                </b>
                {" (addDismissalOtherPersons) "}
                <T message="и" />{" "}
                <b>
                  <T message="по количеству составов преступлений" />
                </b>
                {" (addDismissalOtherOffences)."}
              </li>
            </ol>
          </li>
          <li>
            <b>
              <T message="Признаны невменяемыми: по числу лиц" />
            </b>
            {" (addUnfitToPleadPersons) "}
            <T message="и" />{" "}
            <b>
              <T message="по количеству составов преступлений" />
            </b>
            {" (addUnfitToPleadOffences)."}
          </li>
        </ol>
      </TypographyContainer>
      <Typography isUpperCased>
        <b>
          <T message="Поля выгрузки" />
        </b>
      </Typography>
      <Table
        title={t("Поля выгрузки")}
        isNotPaddedLeft
        hideEmbed
        isEqualWidth
        isNotMarginTop
        tables={tables}
      />
    </>
  );
};

export default FaqPageUploadFieldsNParameterTree;
